import { Box, type BoxProps } from "@radix-ui/themes";
import classNames from "classnames";
import "./StickyContainer.scss";

type StickyContainerProps = BoxProps & {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
};

/**
 * Utility component that displays scrollable content with a sticky header and/or footer
 */
export default function StickyContainer(
  props: React.PropsWithChildren<StickyContainerProps>
) {
  const { className, header, children, footer, ...boxProps } = props;
  return (
    <Box {...boxProps} className={classNames("StickyContainer", className)}>
      {props.header ? (
        <Box className="StickyContainerHeader">{header}</Box>
      ) : null}
      <Box className="StickyContainerContent">{children}</Box>
      {props.footer ? (
        <Box className="StickyContainerFooter">{footer}</Box>
      ) : null}
    </Box>
  );
}
